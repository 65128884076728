import "core-js/modules/es.array.push.js";
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue";
import _imports_0 from '@/assets/images/avatar.png';
import _imports_1 from '@/assets/images/copy.png';
const _hoisted_1 = {
  class: "me-head"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 1,
  class: "me-avatar",
  src: _imports_0,
  alt: ""
};
const _hoisted_4 = {
  class: "me-head__right"
};
const _hoisted_5 = {
  class: "name"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "acea-row row-center-wrapper codes"
};
const _hoisted_8 = {
  class: "uid"
};
const _hoisted_9 = {
  class: "inviteCode"
};
const _hoisted_10 = {
  class: "me-card"
};
const _hoisted_11 = ["src"];
const _hoisted_12 = {
  class: "me-card__content acea-row row-between-wrapper"
};
const _hoisted_13 = {
  class: "titles acea-row row-between-wrapper"
};
const _hoisted_14 = {
  class: "fs-18"
};
const _hoisted_15 = {
  class: "loginOut"
};
const _hoisted_16 = {
  key: 1,
  class: "Withdrawal"
};
const _hoisted_17 = {
  key: 2,
  class: "Recharge"
};
import Tabbar from '@/components/Tabbar/Tabbar.vue';
import { useUserStore } from '@/stores/user';
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { router } from '@/router';
import { useClipboard } from '@vueuse/core';
import { showToast } from 'vant';
import { useI18n } from "vue-i18n";
import enUS from 'vant/es/locale/lang/en-US'; // 英语
import frFR from 'vant/es/locale/lang/fr-FR'; // 法语
import enES from 'vant/es/locale/lang/es-ES'; // 西班牙语
import itIT from 'vant/es/locale/lang/it-IT'; // 意大利语
import vi from "vant/es/locale/lang/vi-VN";
import { Locale } from 'vant';
import { pngHashMap } from "@/assets/levelmg";
import Withdrawal from "@/view/withdrawal.vue";
import Recharge from "@/view/recharge.vue";
import { useRoute } from "vue-router";
export default {
  __name: 'me',
  setup(__props) {
    const userStore = useUserStore();
    const localeActions = computed(() => userStore.localeActions);
    const levelImg = i => {
      return pngHashMap.get('lv' + i).icon;
    };
    const leveImgBg = i => {
      return pngHashMap.get('lb' + i).icon;
    };
    let showInfos = ref(0);
    const targetElement = ref(null);
    const navBg = ref(false);
    let scrollHandler = null;
    const setShowInfo = i => {
      showInfos.value = i;
    };
    const route = useRoute();
    const setInfosIndex = route.query.info ? parseFloat(route.query.info) : '';
    if (setInfosIndex) {
      setShowInfo(setInfosIndex);
    }
    const handleScroll = () => {
      const distanceToTop = window.scrollY;
      if (distanceToTop > 0) {
        navBg.value = true;
      } else {
        navBg.value = false;
      }
    };
    onMounted(() => {
      scrollHandler = handleScroll;
      window.addEventListener('scroll', scrollHandler);
      handleScroll(); // 初始调用
    });
    onUnmounted(() => {
      window.removeEventListener('scroll', scrollHandler);
    });
    const show = ref(false);
    const {
      t,
      locale
    } = useI18n();
    const {
      copy,
      isSupported
    } = useClipboard();
    userStore.updateUser();
    userStore.getBalance();
    userStore.getAuthState();
    const userinfo = computed(() => userStore.userinfo);
    const walletBalance = computed(() => userStore.walletBalance);
    const isAuth = computed(() => userStore.isAuth);
    const onSelect = item => {
      changeLang(item.value);
      show.value = false;
    };
    const _copy = () => {
      copy(userinfo.value.invite_code).then(() => {
        if (!isSupported.value) {
          return showToast(t('current_browser_does_not_support_copy'));
        }
        showToast(t('copy_successful'));
      });
    };
    const jumpTo = url => {
      if (['/withdrawal', '/recharge'].includes(url) && !isAuth.value) {
        return showToast(t('please_authenticate_first'));
      }
      router.push(url);
    };
    const _logout = () => {
      userStore.logout();
      ssq.push('clearUser');
      router.push('/login');
      setTimeout(() => {
        window.location.reload();
      }, 500);
    };
    const changeLang = lang => {
      locale.value = lang;
      const langMap = computed(() => userStore.langMapSet).value;
      Locale.use(langMap[lang]['key'], langMap[lang]['value']);
      userStore.setLang(lang);
      window.localStorage.setItem('lang', lang);
    };
    return (_ctx, _cache) => {
      var _walletBalance$value, _walletBalance$value2;
      const _component_van_text_ellipsis = _resolveComponent("van-text-ellipsis");
      const _component_van_button = _resolveComponent("van-button");
      return _openBlock(), _createElementBlock(_Fragment, null, [_unref(showInfos) === 0 ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "me",
        ref_key: "targetElement",
        ref: targetElement
      }, [_createElementVNode("div", _hoisted_1, [userinfo.value.avatar ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "me-avatar",
        src: userinfo.value.avatar,
        alt: ""
      }, null, 8, _hoisted_2)) : (_openBlock(), _createElementBlock("img", _hoisted_3)), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", null, [_createElementVNode("img", {
        src: levelImg(userinfo.value.vip),
        alt: ""
      }, null, 8, _hoisted_6)]), _createElementVNode("span", null, [_createVNode(_component_van_text_ellipsis, {
        content: userinfo.value.name
      }, null, 8, ["content"])])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("p", _hoisted_8, "UID:" + _toDisplayString(userinfo.value.uid), 1), _createElementVNode("p", _hoisted_9, [_createTextVNode(_toDisplayString(_ctx.$t('invitation_code')) + ":" + _toDisplayString(userinfo.value.invite_code) + " ", 1), _createElementVNode("img", {
        class: "me-copy",
        src: _imports_1,
        alt: "",
        onClick: _copy
      })])])])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("img", {
        class: "me-card__bg",
        src: leveImgBg(userinfo.value.vip),
        alt: ""
      }, null, 8, _hoisted_11), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_13, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('newMe.balance')), 1)]), _createElementVNode("p", _hoisted_14, [_createTextVNode(_toDisplayString(_ctx.$public.keepFulls((_walletBalance$value = walletBalance.value) === null || _walletBalance$value === void 0 ? void 0 : _walletBalance$value.balance)) + " ", 1), _cache[4] || (_cache[4] = _createElementVNode("span", null, "฿", -1))]), _createElementVNode("p", null, _toDisplayString(_ctx.$public.keepFulls((_walletBalance$value2 = walletBalance.value) === null || _walletBalance$value2 === void 0 ? void 0 : _walletBalance$value2.earning)) + " ฿ " + _toDisplayString(_ctx.$t('today')), 1)]), _createElementVNode("div", null, [_createVNode(_component_van_button, {
        round: "",
        style: {
          "margin-right": "10px"
        },
        onClick: _cache[0] || (_cache[0] = $event => setShowInfo(1))
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('withdraw')), 1)]),
        _: 1
      }), _createVNode(_component_van_button, {
        round: "",
        style: {},
        onClick: _cache[1] || (_cache[1] = $event => setShowInfo(2))
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('deposit')), 1)]),
        _: 1
      })])])]), _createElementVNode("div", _hoisted_15, [_createVNode(_component_van_button, {
        plain: "",
        block: "",
        round: "",
        color: "#56F9AA",
        onClick: _logout
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('logout')), 1)]),
        _: 1
      })])], 512)) : _createCommentVNode("", true), _unref(showInfos) === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(Withdrawal, {
        onBack: _cache[2] || (_cache[2] = $event => setShowInfo(0))
      })])) : _createCommentVNode("", true), _unref(showInfos) === 2 ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createVNode(Recharge, {
        onBack: _cache[3] || (_cache[3] = $event => setShowInfo(0))
      })])) : _createCommentVNode("", true)], 64);
    };
  }
};